import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { bakeEnv } from '@elemental-concept/env-bakery';
import { Environment } from './environments/environment';

bakeEnv(() => import('./environments/environment')).then((environment: Environment) => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
}).catch(err => console.error(err));
