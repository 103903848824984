import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { DYNAMIC_FORM_COMPONENT_MAP } from '@elemental-concept/dynamic-form';
import { materialComponentMap } from '@elemental-concept/dynamic-form-material';
import { GrappaModule } from '@elemental-concept/grappa';
import { GrappaJwtModule } from '@elemental-concept/grappa-jwt';
import { API_BASE_URL, AUTH_CONFIG, TokenInterceptor } from '@elemental/ec-auth-module';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { StructuresModule } from './modules/structures/structures.module';

import { translationSections } from './app.translations';

import { authConfig } from './modules/auth/types';

import { MAT_DATE_FORMATS } from '@angular/material/core';
import { AppComponent } from './app.component';

export function HttpLoaderFactory(_httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(_httpBackend, [...translationSections]);
}

export function getApiUrl() {
  return environment().apiUrl;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,

    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),

    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatMomentDateModule,

    GrappaModule,
    GrappaJwtModule,

    StructuresModule
  ],
  providers: [
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'dd/MM/yyyy' } },

    // Dynamic Form mapping object using Dynamic Form Material map
    { provide: DYNAMIC_FORM_COMPONENT_MAP, useValue: materialComponentMap },

    // set the default for the snack bar
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      }
    },

    // point interceptor to Auth Library service
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },

    // Pass baseUrl to the Auth Library
    {
      provide: API_BASE_URL,
      useFactory: getApiUrl,
      deps: []
    },

    // Datepicker config
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    },

    // Pass auth config to Auth Library
    { provide: AUTH_CONFIG, useValue: authConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
