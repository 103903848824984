import { getEnv } from '@elemental-concept/env-bakery';

export type Environment = {
  production: boolean;

  apiUrl: string;
};

export const environment = (): Environment => ({
  production: getEnv('PRODUCTION').boolean(),

  apiUrl: getEnv('API_URL').string()
});
