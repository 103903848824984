import { AuthConfig } from '@elemental/ec-auth-module';

export enum RoleType {
  customer = 'ROLE_CUSTOMER',
  guest = 'ROLE_GUEST'
}

export const mapStringToRoleType: Record<string, RoleType> = {
  'ROLE_CUSTOMER': RoleType.customer,
  'ROLE_GUEST': RoleType.guest
};

export const authConfig: AuthConfig = {
  guestRole: RoleType.guest,

  rootUrl: '/auth/login',

  changePasswordUrl: '/auth/change-password',

  landingPages: {
    [RoleType.customer]: '/customer',
    [RoleType.guest]: '/public'
  }
};
