import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private readonly router: Router
  ) {
    const routeEventsUrl: string[] = [];
    this.router.events.pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      filter((event: any) => event instanceof NavigationStart)
    ).subscribe(event => {
      // accept url except /auth/login
      routeEventsUrl.push(event.url !== '/auth/login' ? event.url : '/');
      localStorage.setItem('previousUrl', routeEventsUrl[0]);
    });
  }
}
