import { ApiResponse } from '@elemental/ec-auth-module';

import { CustomerDTO } from '../../customer/types';

export enum UserType {
  member = 'MEMBER',
  guest = 'GUEST'
}

export type ProfileInfo = CustomerDTO & {
  userType: UserType;
};

export const guestProfileInfo: ProfileInfo = {
  userType: UserType.guest,
  firstName: 'Guest',
  lastName: '',
  email: '',
  mobile: '',
  companyName: '',
  uen: '',
  industry: '',
  score: null,
  walletBalance: 0,
  requireRecommendation: false,
  showRiskAssessment: false,
  riskProfileDate: '',
  status: 'LEAD',
  id: '',
  addrBlockNo: null,
  addrRoadName: null,
  addrBuilding: null,
  addrLevel: null,
  addrUnitNo: null,
  addrPostalCode: null
};

export type ProfileInfoResponse = ApiResponse<ProfileInfo>;
